import React from 'react'
import '../../css/general.css'

const ExitBtn = ({onClick, type='button'}) => {
  return (
    <button className='btn-tools' onClick={() => onClick()} type={type}>
      X
    </button>
  )
}

export default ExitBtn