import React from 'react'
import '../../css/general.css'
import '../../css/dividers-svgs.css'

const SocialSelect = ({icon = null, url = null, onClick=null, selected=false, id}) => {
  return (
    <button className={selected ? 'social-select-btn selected' : 'social-select-btn'} 
    onClick={() => onClick(id)} name={id} id={id} type="button">
        {icon}
    </button>
  )
}

export default SocialSelect