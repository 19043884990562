import React from 'react'
import {ReactComponent as InstagramIcon} from '../../assets/social-media-icons/instagram.svg'
import '../../css/social-icons.css'

const Instagram = () => {
  return (
    <InstagramIcon
    className="social-icon"
    viewBox="0 0 48 48"
    />
  )
}

export default Instagram