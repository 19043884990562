import React, {useState, useContext} from 'react'
import AuthContext from '../../context/AuthContext'
import { subscriptionPlans } from '../../utils/ApiEndPoints'
import CustomFetch from '../../utils/CustomFetch'
import Loading1 from '../loading-errors-success/Loading1'
import Error1 from '../loading-errors-success/Error1'
import Success1 from '../loading-errors-success/Success1'
import BtnMain from '../buttons-inputs/BtnMain'
import '../../css/popups.css'
import '../../css/general.css'

const ChangePlan = ({onClose, plan}) => {
  const {UpdatePlan} = subscriptionPlans
  const {handleUpdateAccountSubscriptionState} = useContext(AuthContext)

  const [loading, setLoading] = useState(() => false)
  const [error, setError] = useState(() => false)
  const [success, setSuccess] = useState(() => false)

  const handleChangePlan = async () => {

    setLoading(() => true)
    const {response, data} = await CustomFetch(`${UpdatePlan.url}${plan.plan_id}/`, {method:UpdatePlan.method})
    console.log(response)
    setLoading(() => false)
    if(response.status === UpdatePlan.success){
        handleUpdateAccountSubscriptionState(data)
        setSuccess(() => true)
        return
    }
    setError({error:'update', message:'An Error has occurred try again later'})
  }
  
  return (
    <div className='popup-general'>
      <div className='w-90 justify-content-end p-10'>
          <h3 className='text-primary' onClick={onClose}>X</h3>
      </div>
      <div className='w-100 justify-content-center flex-wrap'>
      {!loading && !success && !error &&
          <>
              <h3 className='w-100 text-primary'>Change Plan to {plan.title} ?</h3>
              <BtnMain text={'Change'} onClick={() => handleChangePlan()}/>
          </>
      }
      {loading &&
          <Loading1/>
      }
      {error &&
          <Error1 error={error}/>
      }
      {success &&
          <Success1 />
      }
      </div>
  </div>
  )
}

export default ChangePlan