import React, {useState, useRef, useEffect, useContext} from 'react'
import '../../css/general.css'
import '../../css/forms.css'
import  '../../css/calendar.css'
import PostCarousel from '../posts/PostCarousel'
import platformInformation from '../../utils/PlatformInformation'
import BtnFileUpload from '../buttons-inputs/BtnFileUpload'
import Divider1 from '../dividers-logos/Divider1'
import BtnMain from '../buttons-inputs/BtnMain'
import TextareaAutosize from 'react-textarea-autosize';
import Error1 from '../loading-errors-success/Error1'
import Input1 from '../buttons-inputs/Input1'
import {CheckFiles, memoizeSocialSpecs} from '../../utils/FileInputChecker'
import PlatformSelectorV2 from './PlatformSelectorV2'
import CalendarInput from './CalendarInput'

const UploadFormV2 = ({action, inputError, setDate}) => {
    const body = useRef()
    //cached specs from selected platforms
    //used for file and input checking
    //to make sure sizes are within min and max sizes
    const platformSpecs = useRef({})

    const [selectedAccounts, setSelectedAccounts] = useState({})
    const selectedPlatforms = useRef([])

    const [error, setError] = useState(() => inputError)
    const [files, setFiles] = useState(() => null)
   

    
    // set body = to input
        const handleSetBody = (e) => {
            if(!selectedAccounts || !platformSpecs.current){
                setError({error:'body', errorMessage:'Select accounts first'})
            }


            if(platformSpecs.current.maxText > e.target.value.length){
                setError({error:'body', errorMessage:`Body too long (max length:${platformSpecs.current.maxText})
                        shorten body or delete tags`})
                return
            }
            body.current = e.target.value
        } 
  
    
    const handleSetFile = (e) => {


        //make sure platforms and accounts are selected first
        //so file specs to can be accurately assesed
        if(Object.keys(selectedAccounts).length < 1){
            setError({error:'platforms', errorMessage:'Select Social Accounts First'})
            return
        }

      const fileInputs = Object.values(e.target.files)
        const {returnFileList, error} = CheckFiles(fileInputs, selectedPlatforms.current)

        if(error && error.error){
              setError({error:null, errorMessage:error.error})
              setFiles(() => false)
              return
          }
            setFiles(returnFileList)
        }

        

    useEffect(()=>{
        if(!platformInformation) return
        
        let keys = Object.keys(selectedAccounts)
        if(keys.length === 0) return

        for(let i = 0; i < platformInformation.length; i++){
            if(keys.includes(platformInformation[i].name)){
                selectedPlatforms.current.push(platformInformation[i])
            }
        }


        const specs = memoizeSocialSpecs(selectedPlatforms.current)
        platformSpecs.current = specs
    }, [selectedAccounts])

    const submitForm = (e) => {
        action(e, selectedAccounts)
    }
  
    return (
        <form method='POST' encType='multipart/form-data' className='form-main' id='upload-form' onSubmit = {(e) => submitForm(e)}>
            <h2 className='text-primary'>Schedule Social Media Post</h2>
                {error &&
                    <div className='w-100 justify-content-center'>
                        <Error1 error={error}/>
                    </div>
                }
            

            <div className='w-100 p-20'>
                <Input1 
                id='nickname' 
                placeholder = 'Nickname (only seen here)' 
                maxlength = {25}
                error = {error.error}
                />
            </div>
        
            <Divider1/>
            <div className='p-20'>
                <h2 className = 'text-primary'> Select Accounts </h2>
                <PlatformSelectorV2 
                selectedAccounts ={selectedAccounts} 
                setSelectedAccounts = {setSelectedAccounts}
                id='platforms' 
                error = {error.error}
                />
            </div>

            <Divider1/>

            <div className='p-20 w-100 justify-content-center flex-wrap'>
                <div className='w-100 justify-content-center'>
                    <h2 className='text-primary'>Choose Files to Upload (optionl)</h2>
                </div>

                <div className='w-100 justify-content-center'>
                    <BtnFileUpload 
                    error = {error.error} 
                    text={'Choose Files'} 
                    onChange={(e) => {handleSetFile(e)}}
                    />
                </div>
            </div>
            
            {files &&
                <div className='m-20 w-100 justify-content-center'>
                    {files && 
                        <PostCarousel files={files}/>
                    }
                </div>
            }

            <Divider1/>
            <div className='w-100 justify-content-center p-20'>
                <TextareaAutosize
                id='body'
                name='body'
                minRows={5}
                className={error.error === 'body'? 'resize-input error' : 'resize-input'}
                placeholder='Post Body (Optional)'
                onChange = {(e) => {handleSetBody(e)}}
                />
            </div>

            <Divider1/>

            <div className='justify-content-center flex-wrap m-20 p-20'>
                <div className='w-100 justify-content-center'>
                    <h2 className='text-primary'>Schedule Publishing Time</h2>
                </div>
                <div className='w-50 justify-content-center'>
                    <CalendarInput setDate={setDate}/>
                </div>
            </div>
            <Divider1/>

            <div className='w-100 m-20 justify-content-center'>
                <BtnMain text={'Schedule It'} form="upload-form"/>
            </div>
        </form>
  )
}

export default UploadFormV2