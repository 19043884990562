import React from 'react'
import MobileNavIcon from './MobileNavIcon'
import Logo from './Logo'
import '../../css/navbar.css'
import '../../css/general.css'

const Navbar = ({toggle, mobile=false}) => {
  return (
    <div className={mobile ? 'nav-container mobile-nav' : 'nav-container non-mobile-nav'}>
            <div className='w-50 justify-content-center'>
              <Logo/>
              <h3 className='m-30 text-theme-secondary'>Beta</h3>
            </div>

                <MobileNavIcon onClick={toggle}/>

        <div className='nav-liner'></div>
    </div>
  )
}

export default Navbar