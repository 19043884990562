import React, {createContext, useContext, useCallback, useState, useEffect, useRef} from 'react'
import AuthContext from './AuthContext'
import CustomFetch from '../utils/CustomFetch';
import {postUrls} from '../utils/ApiEndPoints'
import {CountRenders} from '../utils/CountRenders'

const PostsContext = createContext()

export default PostsContext;

export const PostsProvider = React.memo(({children}) =>{
    CountRenders('Post Context: ')

    const initialLoad = useRef(true)
    const {pastPostsUrl, futurePostsUrl} = postUrls
    const {User, UserProfile} = useContext(AuthContext)
    //for future scheduled posts
    const [posts, setPosts] = useState(() => false)
    const [paginateNext, setPaginateNext] = useState(() => false)

    //initial get posts handler
    const handleInitialPostsState = async () => {
        if(UserProfile.recent_posts){
            setPosts({results:[UserProfile.recent_posts]})
            return
        }
        if(localStorage.getItem('RecentPosts')){
            const postList = JSON.parse(localStorage.getItem('RecentPosts'))
            setPosts({results:postList})
            return
        }
        // if all of the above fail
        await getPosts()
    }


    const getPosts = useCallback( async () => {
        const {response, data} = await CustomFetch(futurePostsUrl.url)
        if(response.status === 200){
            console.log(data)
            setPosts({results:[...data.results], ...data})
            setPaginateNext(() => data.next)
        }
        return response.status
    }, [futurePostsUrl.url])

    const paginatePosts = async () => {
        if(!paginateNext) return
        const {response, data} = await CustomFetch(posts.next)
        if (response.status === 200){
            setPosts((oldDict) => (
                {
                   results : [...oldDict.results, ...data.results],
                   ...data
                }
            ))
            setPaginateNext(() => data.next)
        }
    }
    
    //for past posts
    const [pastPosts, setPastPosts] = useState(() => [])

    const getPastPosts = useCallback(async () => {
        const {response, data} = await CustomFetch(pastPostsUrl.url)
        if(response.status === 200){
            setPastPosts({results:[...data.results], ...data})
        }
    }, [pastPostsUrl])

    const paginatePastPosts = async () => {
        if(!pastPosts.next) return
        const {response, data} = await CustomFetch(pastPosts.next)
        if(response.status === 200) {
            setPastPosts((oldDict) => ({
                ...oldDict, results : [...oldDict.results, ...data.results],
                next:data.next 
            }))
        }
    }


    //sort and place new post in post accordingly
    //for updating all state and local storage
    //when user schedules a post
    const handleAddPostToState = (newPost) => {
        for(let i = 0; i < posts.results.length; i++){
            //Find if post is between dates
            let newPostDate = Date.parse(newPost.scheduled)
            let curIndexDate = Date.parse(posts.results[i].scheduled)
            let nextIndexDate = Date.parse(posts.results[i + 1].scheduled)
            
            if(newPostDate >= curIndexDate && newPostDate <= nextIndexDate){
                let index = + 1
                let firstHalf = posts.results.slice(0, index)
                let secondHalf = posts.results.slice(-index)
                const newPostList = [...firstHalf, newPost, ...secondHalf]
                //set local state
                setPosts((oldState) => ({
                    ...oldState, results:[...newPostList]
                }))

                return
            }    
        
        }
    }

    //runs on initial load to retrieve users posts
    useEffect(() => {
      if(!User) return
      if(User === null) return
      if(!initialLoad.current) return

      const initialPostFetch = async () => {
            const fetchPostStatus = await handleInitialPostsState()
            if(fetchPostStatus === 200){
                initialLoad.current = false
            }
        }
        
    initialPostFetch()

    }, [User])
    



    const contextData = {
        getPastPosts:getPastPosts,
        paginatePastPosts:paginatePastPosts,
        posts:posts,
        pastPosts:pastPosts,
        paginatePosts:paginatePosts,
        handleAddPostToState:handleAddPostToState,
    }

    return (
        <PostsContext.Provider value={contextData}>
            {children}
        </PostsContext.Provider>
    )

})