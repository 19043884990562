import React from 'react'
import {ReactComponent as Tumb} from '../../assets/social-media-icons/tumblr.svg'
import '../../css/social-icons.css'

const Tumblr = () => {
  return (
    <Tumb 
    viewBox="0 0 48 48"
    className="social-icon"
    />
  )
}

export default Tumblr