import React, {useState} from 'react'
import LoginForm from '../../components/forms/LoginForm'
import SignupForm from '../../components/forms/SignupForm'
import Btn2 from '../../components/buttons-inputs/Btn2'
import '../../css/forms.css'
import '../../css/general.css'


const LoginSignup = () => {
    const [toggleLogin, setToggleLogin] = useState(() => true)
    const toggle = () => {
      setToggleLogin(!toggleLogin)
    }

  return (
    <div className='w-100 mt-50'>
      <div className={toggleLogin ? 'mt-20 form-login' : 'mt-20 form-login signup'}>
              <div className='w-75 justify-content-space-around align-items p-0 m-0'>
                  <Btn2 text='Login' muted={toggleLogin ? false : true} onClick={toggle}/>
                  <h1 className='text-theme-secondary p-0 m-0'> | </h1>
                  <Btn2 text='Signup' muted={toggleLogin ? true : false} onClick={toggle}/>

              </div>
            {toggleLogin ? 
              <LoginForm/>
              :
              <SignupForm/>
            }
          </div>
    </div>
    
  )
}

export default LoginSignup