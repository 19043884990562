import React, {useState, useContext} from 'react'
import AuthContext from '../../context/AuthContext'
import AddPlatformsPopup from './AddPlatformsPopup'
import MyPlatformsItem from './MyPlatformsItem'
import AddBtn from '../buttons-inputs/AddBtn'
import '../../css/platforms.css'
import '../../css/general.css'
import '../../css/containers.css'

const PlatformsContainer = () => {
    const {userSocials} = useContext(AuthContext)

    const [toggleAddPopup, setToggleAddPopup] = useState(() => false)
    const handleTogglePopup = () => {setToggleAddPopup(!toggleAddPopup)}

    const handleRenderSocials = (socials) => {
      const items = []
      for(const value of Object.values(socials)){
        value.forEach(element => {
          items.push(<MyPlatformsItem social={element} key = {`${element.id}-my-platform-item`}/>)
        })
      }
      return items
    }
  return (
    <div className='card-container'>
          {toggleAddPopup &&
            <AddPlatformsPopup handleClosePopup={handleTogglePopup}/>
          }
        <div className='platform-header'>
          <div className='w-75 p-10'>
                  <h3 className='text-primary m-0 p-0'> Your Social Platforms</h3>
          </div>
          <div className='w-25 p-10 justify-content-end'>
              <AddBtn onClick={handleTogglePopup}/>
          </div>
        </div>

        
        <div className='platform-scrollable-container'>
          
          {!userSocials &&
            <>
              <h4 className='text-primary'>Nothing to show</h4>
              <div className='w-100 justify-content-center'>
                <AddBtn onClick={handleTogglePopup}/>
              </div>
            </>
          }
          {userSocials &&
            handleRenderSocials(userSocials).map((account => {
              return account
            }))
          }
        </div>
        <div className='w-100 m-20'></div>
    </div>
  )
}

export default PlatformsContainer