import React from 'react'
import {ReactComponent as TwitterIcon} from '../../assets/social-media-icons/twitter.svg'
import '../../css/social-icons.css'

const Twitter = () => {
  return (
    <TwitterIcon
    viewBox="0 0 48 48"
    className="social-icon"
    />
  )
}

export default Twitter