import React from 'react'
import AddBtn from '../buttons-inputs/AddBtn'
import getSocialIcon from '../../utils/GetSocialIcon'

import '../../css/platforms.css'
import '../../css/general.css'

const SupportedPlatformItem = ({platform, onClick}) => {
  return (
    <div className='platform-select-item'>
        {getSocialIcon(platform.name)}
        <h3 className='text-primary'>{platform.name}</h3>
        <AddBtn onClick={() => onClick(platform.name)}/>
    </div>
  )
}

export default SupportedPlatformItem