import React from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import UpdateCardForm from './UpdateCardForm'
import '../../css/checkout.css'
import '../../css/general.css'
import '../../css/popups.css'

const UpdateCardPopup = ({onComplete, onClose}) => {
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_SECRET)
    console.log()
    return (
    <div className='popup-general'>
        <div className='w-90 justify-content-end p-10'>
            <h3 className='m-0 p-0 text-primary' onClick={onClose}>X</h3>
        </div>
        
        {stripePromise ?
            <Elements stripe={stripePromise}>
                <UpdateCardForm
                    onComplete={onComplete} 
                    onClose={onClose}
                />
            </Elements>
            :
            <h1>Loading</h1>
            } 
    </div>
  )
}

export default React.memo(UpdateCardPopup)