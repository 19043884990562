import React, {useContext, useState} from 'react'
import { useHistory } from 'react-router-dom'
import '../../css/forms.css'
import AuthContext from '../../context/AuthContext'
import Error1 from '../loading-errors-success/Error1'
import Loading1 from '../loading-errors-success/Loading1'
import '../../css/general.css'
import Input1 from '../buttons-inputs/Input1'
import BtnMain from '../buttons-inputs/BtnMain'
import FormInputChecker from '../../utils/FormInputChecker'

const LoginForm = () => {
    const {loginUser} = useContext(AuthContext)
    const [loading, setLoading] = useState(() => false)
    const [error, setError] = useState(() => false)

    const history = useHistory()

    const handleLogin = async (e) => {
        e.preventDefault()
        const inputError = FormInputChecker(e, setError)
        if(inputError) return
        
        setLoading(() => true)
        
        const error = await loginUser(e.target.username.value, e.target.password.value)
        if(!error){
            history.push('/home')
        }
        setError(error)
        setLoading(false)
    }

  return (
    <form onSubmit={handleLogin} id='login-form' className='error form-login inner-form'>
        {!loading &&
            <div className='w-75 justify-content-center flex-wrap'>
                <Input1 error={error} placeholder='Username' type='text' id='username'/>
                <Input1 error={error} placeholder='Password' type='password' id='password'/>
                <BtnMain form='login-form' text='Login'/>
            </div>
        }
        {loading &&
            <Loading1/>
        }

        {
            error &&
            <div className='mt-10 w-75'>
                <Error1 error = {error}/>
            </div>
        }
    </form>
  )
}

export default LoginForm