import React, {useState, useContext} from 'react'
import AuthContext from '../../context/AuthContext'
import { useHistory, useParams } from 'react-router-dom'
import CustomFetch from '../../utils/CustomFetch'
import { checkoutUrls} from '../../utils/ApiEndPoints'
import { CardElement, useStripe, useElements} from '@stripe/react-stripe-js'
import Loading1 from '../loading-errors-success/Loading1'
import Success1 from '../loading-errors-success/Success1'
import BtnMain from '../buttons-inputs/BtnMain'
import {CountRenders} from '../../utils/CountRenders'
import '../../css/checkout.css'
import '../../css/general.css'

const CheckoutPopup = ({onComplete, onClose, plan,}) => {

    //for styling stripe card element
    const cardOptions= {
        style: {
          base: {
            fontSize: '20px',
            color: 'rgb(236, 237, 238)',
            '::placeholder': {
              color: 'rgb(236, 237, 238)',
            },
          },
          invalid: {
            color: '#9e2146',
          },
        },
      }

    const number = Math.floor(plan.price / 100)
    const str_amount = `$${number}`

    const stripe = useStripe()
    const elements = useElements()

    
    const {CreateStripePaymentSecret} = checkoutUrls

    const [btnDisabled, setBtnDisabled] = useState(() => false)
    const [processing, setProcessing] = useState(() => false)
    const [showError, setShowError] = useState(() => false)
    const [success, setSuccess] = useState(() => false)



    const handlePayment = async () =>{
        setBtnDisabled(true)
        setProcessing(true)
        const status = await subscriptionPurchase()
        setProcessing(false)
        if(!status){
            setBtnDisabled(false)
            setShowError({error:"card info", message: "An Error has occurred, try again later"})
            return
        }
        setSuccess(true)
        onComplete()
    }

    const subscriptionPurchase = async () => {
        const fetchConfig = {method: 'POST'}
        const {response, data} = await CustomFetch(`${CreateStripePaymentSecret.url}${plan.pk}`, fetchConfig)
        console.log(data)
        console.log(data.client_secret)
        if(response.status === 201){
            const {error, paymentIntent} = await stripe.confirmCardPayment(data.client_secret, {
                payment_method:{
                    type: 'card',
                    card: elements.getElement(CardElement),
                    }
                })

            if(error){
                return false
            }
            return true
        }
    }
    

  return (
    
    <div className='popup-content-container'>
        <div className='justify-content-center w-100'>
            <h2 className='text-primary m-0 p-0'>{str_amount}</h2>
        </div>
            <div className='card-input-wrapper'>
                <CardElement options={cardOptions}/>
            </div>
            <div className='justify-content-center m-20 p-10 w-100'>
                { processing ? <Loading1/> :
                    <BtnMain text="Confirm Payment" onClick={()=>handlePayment()} disabled={!stripe || !elements || btnDisabled}/>
                }
            </div>
           

        {showError &&
        <>
            <div className='justify-content-center'>
                <h2>An Error has occurred, please try again later</h2>
            </div>
        </>
        }
        {success &&
            <Success1/>
        }

    </div>
  )
}

export default React.memo(CheckoutPopup)