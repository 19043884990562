import React, {createContext, useState, useEffect, useCallback} from "react";
import jwt_decode from 'jwt-decode'
import { loginUrl, loginRefreshUrl, tokenExp, authUserUrls, userProfileUrls} from "../utils/ApiEndPoints";
import { useHistory } from "react-router-dom";
import GetCookie from "../utils/GetCookie";
import CustomFetch from "../utils/CustomFetch";
import dayjs from 'dayjs'


const AuthContext = createContext()

export default AuthContext;

export const AuthProvider = React.memo(({children}) => {

    const [AuthTokens, setAuthTokens] = useState(() => localStorage.getItem('authTokens') ? JSON.parse((localStorage.getItem('authTokens'))) :  null)
    const [User, setUser] = useState(() => localStorage.getItem('authTokens') ? jwt_decode(JSON.parse(localStorage.getItem('authTokens'))?.access) : null)
    const [UserProfile, setUserProfile] = useState(() => localStorage.getItem('UserProfile') ? JSON.parse(localStorage.getItem('UserProfile')) : null)
    const [userSocials, setUserSocials] = useState(() => localStorage.getItem('UserSocials') ? JSON.parse(localStorage.getItem('UserSocials')) : null)
    const [Account, setAccount] = useState(() => localStorage.getItem('Account') ? JSON.parse(localStorage.getItem('Account')) : null)
    const [loading, setLoading] = useState(() => true)
    const history = useHistory()

    const {passwordReset, passwordResetConfirm} = authUserUrls

    //sorts through all of users social platforms
    //into a dictionary with the platform name as key
    // and an array as value containing corresponding
    //platforms
    const sortUserSocialPlatforms = (socialList) => {
       const sortedSocials = {}
        for(let x = 0; x < socialList.length; x++){
            let soc = socialList[x]

            if(sortedSocials[soc.platform.name]){
                sortedSocials[soc.platform.name].push(soc)
            }
            else{
                sortedSocials[soc.platform.name] = [soc]
            }
  
        }
        return sortedSocials
    }

    //adds additional social to users socials list
    const updateSocials = (newSocial) => {
        if(userSocials[newSocial.platform.name]){
            userSocials[newSocial.platform.name].push(newSocial)
        }
        else{
            userSocials[newSocial.platform.name] = newSocial
        }
    }

    const loginUser = useCallback(async (username, password) => {
        const payload = {
            'username':username,
            'password':password
        }
        //handle user authentication
        const response = await fetch(loginUrl, {
            method:'POST',
            headers: {
                'Content-Type':'application/json'
            }, 
            body:JSON.stringify(payload)
        })

        //if successful retrieve user profile info
        if (response.status === 200){
            const data = await response.json()
            const user = jwt_decode(data.access)
            //set auth tokens in local state
            setAuthTokens(() => data)
            setUser(() => user)
            //set auth tokens in local storage
            localStorage.setItem('authTokens', JSON.stringify(data))
            localStorage.setItem('user', JSON.stringify(user))
            
            //retrieve user profile info
            const {ProfileResponse, ProfileData} = await getUserProfileInfo(user.id)
            if(ProfileResponse.status === 200){
                //if profile fetch is successful return error false
                history.push('/home')
                return false
            }
            else{return "Try again could not get profile info"} 

        }
        else{
            return "Invalid login credentials"
        } 
    }, [setAuthTokens])

    const logoutUser = useCallback(() => {
        localStorage.removeItem('authTokens')
        setAuthTokens(null)
        localStorage.removeItem('user')
        setUser(null)
        localStorage.removeItem('UserProfile')
        setUserProfile(null)
        localStorage.removeItem('Account')
        setUserProfile(null)
        history.push('/login-signup')
    },[setAuthTokens])



    const getUserProfileInfo = useCallback(async (user_id) => {
        const fetchConfig = {method:'get'}
        const {getUserProfile} = userProfileUrls
        const {response, data} = await CustomFetch(`${getUserProfile.url}${user_id}/`, fetchConfig)
        if(response.status === 200){
            const sortedSocialAccounts = sortUserSocialPlatforms(data.account.socials)
            data.socials = sortedSocialAccounts
            localStorage.setItem('UserSocials', JSON.stringify(data.socials))
            
            delete data.account.socials
            
            localStorage.setItem('UserProfile', JSON.stringify(data))
            localStorage.setItem('Account', JSON.stringify(data.account))

            setUserProfile(() => data)
            setUserSocials(() => data.socials)
            setAccount(() => data.account)
        }
        else{
            logoutUser()
            return
        }
        
        const ProfileResponse = response
        const ProfileData = data
        return {ProfileResponse, ProfileData}
    },[setAuthTokens])
              

    
    const updateToken = useCallback(async () => {
        const csrf = GetCookie('csrftoken')
        const refresh = AuthTokens?.refresh
        if(refresh){
            const response = await fetch(loginRefreshUrl, {
                method:"POST",
                headers:{
                    'Content-Type':'application/json',
                    'Authorization':csrf,
                },
                body:JSON.stringify({'refresh': refresh})
            })

            const data = await response.json()
            if('code' in data && data.code === "token_not_valid"){
                logoutUser()
            }

            if(response.status === 200){
                if (data.refresh){
                    localStorage.setItem('authTokens', JSON.stringify(data))
                    setAuthTokens(() => data)
                    const user = jwt_decode(data.access)
                    setUser(() => user)

                    if (!UserProfile){
                        await getUserProfileInfo(user.username)
                    }

                    if(loading){
                        setLoading(false)
                    }
                    return {response, data}
                }
            }
            else{
                logoutUser()
            }
        }

    },[AuthTokens, setAuthTokens, setUser, getUserProfileInfo])

    const handleSetUserProfile = (data) => {
        setUserProfile(data)
    }

    const resetPassword = async (email) => {
        const fetchConfig = {
            method:'POST',
            headers:{
                'Content-Type':'application/json',
            },
            body:JSON.stringify({email:email})
        }
        const response = await fetch(passwordReset.url, fetchConfig)
        return response
        
    }

    const confirmPasswordReset = async (uid, token, newPassword, verifyPassword) => {
        const fetchConfig = {
            method:'POST',
            headers:{
                'Content-Type':'application/json',
            },
            body:JSON.stringify({'uid':uid, 
                                'token':token,
                                'new_password':newPassword,
                                're_new_password':verifyPassword
                                })
        }
        const response = await fetch(passwordResetConfirm.url, fetchConfig)
        return response
    }


    const updateUserProfileState = (info) => {
        const newState = {...UserProfile, ...info}
        setUserProfile((old) => ({
            ...old, ...info
        }))
        const localCheck = localStorage.getItem('UserProfile')
        if (localCheck){
            localStorage.setItem('UserProfile', JSON.stringify(newState))
        }
    }

    const handleUpdateAccountSubscriptionState = (newSubscriptionData) => {
        const newState = {
            ...Account,
            subscription:newSubscriptionData
        }

        setAccount((oldAccount) => ({
            ...oldAccount,
            subscription: newSubscriptionData
        }))
        const localCheck = localStorage.getItem('Account')
        if (localCheck){
            localStorage.setItem('Account', JSON.stringify(newState))
        }
    }

    const contextData = {
        loginUser:loginUser,
        logoutUser:logoutUser,
        User:User,
        setUser : setUser,
        UserProfile:UserProfile,
        handleSetUserProfile:handleSetUserProfile,
        Account:Account,
        AuthTokens:AuthTokens,
        setAuthTokens:setAuthTokens,
        updateToken:updateToken,
        d:resetPassword,
        confirmPasswordReset:confirmPasswordReset,
        getUserProfileInfo:getUserProfileInfo,
        updateSocials:updateSocials,
        userSocials:userSocials,
        updateUserProfileState:updateUserProfileState,
        handleUpdateAccountSubscriptionState:handleUpdateAccountSubscriptionState
    }


     useEffect(() => {
         
        const updateData = async () => { await updateToken();}
        if(loading){
            if(!AuthTokens) return
            const User = jwt_decode(AuthTokens.access)
            const expired = dayjs.unix(User.exp).diff(dayjs()) < 1
            if(!expired) return 
            updateData()
        }

        const interval = setInterval(()=>{
            if(AuthTokens){
                updateData()
            }
        }, tokenExp)
        return ()=> clearInterval(interval)
    }, [AuthTokens, loading])
    

    return (
        <AuthContext.Provider value={contextData}>
            {children}
        </AuthContext.Provider>
    )
})


