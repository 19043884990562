import React from 'react'
import {ReactComponent as Icon} from '../../assets/tool-icons/hamburger.svg'
import '../../css/navbar.css'


const MobileNavIcon = ({onClick}) => {
  return (
    <div className='mobile-nav-icon-btn' onClick={() => onClick()}>
        <Icon className="mobile-nav-icon"  viewBox="0 0 16 16"/>
    </div>
  )
}

export default MobileNavIcon