import React from 'react'
import {useHistory} from 'react-router-dom'
import VerticalDivider1 from '../components/dividers-logos/VerticalDivider1'
import Btn3 from '../components/buttons-inputs/Btn3'

import {ReactComponent as Knot} from '../assets/knot.svg'

import Twitter from '../components/social-icons/Twitter'
import Snapchat from '../components/social-icons/Snapchat'
import Pinterest from '../components/social-icons/Pinterest'
import Facebook from '../components/social-icons/Facebook'
import Instagram from '../components/social-icons/Instagram'
import LinkedIn from '../components/social-icons/LinkedIn'
import Tiktok from '../components/social-icons/Tiktok'
import Tumblr from '../components/social-icons/Tumblr'
import Youtube from '../components/social-icons/Youtube'



import '../css/general.css'
import '../css/landing.css'

const Landing = () => {
    const history = useHistory()

  return (
    <div className='w-100 justify-content-center flex-wrap'>
    <div className='w-100 justify-content-between flex-wrap-reverse align-items'>
        <div className='landing-subcontainer'>
            <h2 className='text-primary w-75'>
                Tailored for you to manage your presence  for all of your social accounts
                in one simple platform.
            </h2>
            <div className='w-100 justify-content-center'>
                <VerticalDivider1/>
            </div>
            <h3 className='text-primary w-50'>We'll upload your scheduled posts, ads, campaigns and 
            more for all of our supported social media platforms</h3>
        </div>
        <div className='landing-subcontainer'>
            <div className='w-100 margin-auto'>
                <div className='w-100 justify-content-center'>
                <h1 className='text-primary p-0 m-0'>Keep Your Workflow Simple</h1>
                </div>
                <Knot viewBox="10 10 90 80"
                className='landing-knot p-0 m-0'/>
                <div className='w-100 justify-content-center'>
                    <h1 className='text-primary p-0 m-0'>Keep Your Plans Twisted</h1>
                </div>
            </div>
        </div>
    </div>
    
<div className='m-50 w-100 justify-content-center flex-wrap'>
    <h2 className='text-primary w-100 m-0 p-0'>Brought to you by Stray Technology</h2>
    <h2 className='text-primary w-100 m-0 p-0'>We believe you have the right to privacy</h2>
    <h2 className='text-primary w-100 m-0 p-0'>So we will never sell or use your information for marketing or profit. Period.</h2>
</div>


<div className='landing-social-container'>
    <div className='w-100 justify-content-center'>
        <h3 className='text-primary'>Current</h3>
    </div>
    <div className='m-20 w-100 justify-content-space-around flex-wrap'>
        <Twitter/>
        <Snapchat/>
        <Pinterest/>
        <Tumblr/>
        <LinkedIn/>
    </div>
    <div className='w-100 justify-content-center' >
        <h3 className='text-primary'>Coming Soon</h3>
    </div>
    <div className='m-20 w-100 justify-content-between flex-wrap'>
        <Facebook/>
        <Instagram/>
        <Youtube/>
        <Tiktok/>
    </div>
    </div>
    <div className='m-20 w-100 justify-content-center'>
            <Btn3 onClick={() => history.push('/login-signup')} text="Get Started"/>
        </div>
    </div>
  )
}

export default Landing