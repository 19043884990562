import React, {useState} from 'react'
import Btn2 from '../../components/buttons-inputs/Btn2'

import UserInfo from '../../components/account/UserInfo'
import Security from '../../components/account/Security'
import BillingUserInfo from '../../components/billing/BillingUserInfo'

import '../../css/general.css'
import '../../css/forms.css'

const Account = () => {

  const [toggleAccount, setToggleAccount] = useState(() => true)
  return (
    <div>
      <div className='form-update justify-content-center'>
        <div className='w-75 justify-content-space-around'>
          <div className='w-50 justify-content-center'>
            <Btn2 text="Account" muted={false} onClick={() => setToggleAccount(true)}/>
          </div>
          <div className='w-50 justify-content-center'>
            <Btn2 text="Billing" onClick={() => setToggleAccount(false)}/>
          </div>
        </div>

      </div>
      {toggleAccount ?
        <>
          <UserInfo/>
          <Security/>
        </>
        :
        <BillingUserInfo />
      }
    </div>
  )
}

export default Account