import React from 'react'
import SocialAccountSelectItem from './SocialAccountSelectItem'
import ExitBtn from '../buttons-inputs/ExitBtn'
import '../../css/general.css'
import '../../css/dividers-svgs.css'
import '../../css/platforms.css'

//for selecting specific account on a single platform
//this allows users to have multiple accounts which they 
//can post to
const SocialAccountSelectPopup = ({socialAccounts, handleSelectAccounts, 
  handleClosePopup, selectedAccountList}) => {

  return (
    <div className='popup-main-container'>
         <div className='platform-popup-container'>
        <div className='w-100 justify-content-end p-10'>
          <ExitBtn onClick={() => handleClosePopup()}/>
        </div>
            <div className='w-100 justify-content-center'>
                <h3 className='text-primary'> Choose Accounts</h3>
            </div>
            <div className='w-100 justify-content-center flex-wrap p-10'>
                {
                  socialAccounts.map((account, index) => {
                      return <SocialAccountSelectItem 
                        key = {`${index}-social-select-item`}
                        account = {account}
                        onClick = {handleSelectAccounts}
                        selectedAccountList = {selectedAccountList.hasOwnProperty(account.platform.name) ? selectedAccountList : false}
                      />
                    })
                }
                
            </div>
        </div>
    </div>
  )
}

export default SocialAccountSelectPopup