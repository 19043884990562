import React, {useState, useContext} from 'react'
import { useHistory } from 'react-router-dom'
import AuthContext from '../../context/AuthContext'
import UpdateCardPopup from '../../components/stripe/UpdateCardPopup'
import CancelPlanVerify from './CancelPlanVerify'
import {convertToFormattedSiteDateShort} from '../../utils/DateFunctions'
import Divider1 from '../dividers-logos/Divider1'
import BtnRed from '../buttons-inputs/BtnRed'
import BtnMain from '../buttons-inputs/BtnMain'
import Btn3 from '../buttons-inputs/Btn3'

import '../../css/general.css'
import '../../css/billing-plans.css'

const BillingUserInfo = () => {
    const {Account} = useContext(AuthContext)
    const history = useHistory()
    const [toggleUpdateCard, setToggleUpdateCard] = useState(() => false)
    const [toggleCancelSubscription, setToggleCancelSubscription] = useState(() => false)

    const handleToggleCancelSubscription = () => {
      setToggleCancelSubscription(!toggleCancelSubscription)
    }
    
    const toggleUpdateCardPopup = () => {
      setToggleUpdateCard(!toggleUpdateCard)
    }




  return (
    <div className='billing-info-container'>
        <div className='current-plan'>
              <h2 className='w-100 justify-content-center text-primary'>Current Plan</h2>
              <h3 className='m-0 p-0 w-100 justify-content-center text-primary'>{Account.subscription.product.title}</h3>
              <h3 className='m-0 p-10 w-100 justify-content-center text-primary'>${Account.subscription.product.price / 100}</h3>
              <h3 className='m-0 p-10 w-100 justify-content-center text-primary'>Renewal Date: {Account.subscription.product.title === 'Basic' ? 'N / A' : convertToFormattedSiteDateShort(Account.subscription.end_date)}</h3>
        </div>
        <div className='p-20 m-20 w-100'>
          <Divider1/>
        </div>
        <div className='subscription-status'>
          <BtnRed text='Cancel Subscription' onClick={() => handleToggleCancelSubscription()}/>
          <BtnMain text='Change Plan' onClick={() => {history.push('/plans')}}/>
        </div>
        {toggleCancelSubscription &&
          <CancelPlanVerify onClose={() => handleToggleCancelSubscription()}/>
        }
        {toggleUpdateCard &&
          <UpdateCardPopup onClose={() => toggleUpdateCardPopup()}/>
        }
        <div className='p-20 m-20 w-100'>
          <Divider1/>
        </div>
        
        <div className='w-100 justify-content-center flex-wrap'>
          {Account.has_default_payment_method &&
            <>
              <h3 className='m-0 p-0 w-100 justify-content-center text-primary'>Card Details</h3>
              <h3 className='m-0 p-20 w-100 justify-content-center text-primary'>XXXX-{Account.card_last_4}</h3>
              <Btn3 text="Update Card" onClick={toggleUpdateCardPopup}/>
            </>
          }
        </div>
        {/* <UpdateCardPopup/> */}
    </div>
  )
}

export default BillingUserInfo