import React from 'react'
import '../../css/buttons-inputs.css'

const BtnFileUpload = ({id='file', text='Upload Video', onChange=null}) => {
  return (
    <label htmlFor={id} className='file-label'>
        <div className ='upload-btn'>
            <h3 className='text-primary'>{text}</h3>
        </div>
        <input multiple onChange={(e) => onChange(e)} id={id} name={id} type='file' accept='video/mp4, image/jpeg' hidden/>
    </label>
  )
}

export default BtnFileUpload