import React,{useRef} from 'react'
import DtPicker from 'react-calendar-datetime-picker'
import 'react-calendar-datetime-picker/dist/index.css'
import '../../css/general.css'
import '../../css/forms.css'
import  '../../css/calendar.css'

const CalendarInput = ({setDate}) => {
    //for calendar range
    //keeps user from scheduling a post outside
    //of supported ranges
    const handleMinAndMaxDates = () => {
        const currDate = new Date()
        const dates = {min: {
            day:currDate.getDate(),
            month:currDate.getMonth() + 1,
            year: currDate.getFullYear()
            },
            max:{
                day:currDate.getDay(),
                month:currDate.getMonth() + 1,
                year: currDate.getFullYear() + 1,  
            }
         }

        console.log(dates)
        return dates
        //setMinMaxDates(dates)
    }

    const minMaxDates = useRef(handleMinAndMaxDates())


  return (
    <DtPicker 
    inputId='calendar'
    onChange={setDate} 
    autoClose = {false}
    local="en"
    withTime = {true}
    showTimeInput = {true}
    minDate={minMaxDates.current && minMaxDates.current.min}
    maxDate={minMaxDates.current && minMaxDates.current.max}
    /*styling */
    placeholder = "Calendar"
    headerClass='header-class'
    calenderModalClass = "calendar-modal-class"
    inputClass = "p-20 text-white btn-calendar"
    daysClass = "days-class"
    />
  )
}

export default React.memo(CalendarInput)