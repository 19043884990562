import React from 'react'
import {useHistory} from 'react-router-dom'
import Profile from '../../assets/tool-icons/profile-user.png'

import '../../css/navbar.css'

const ProfileBtn = () => {
    const history = useHistory()
  return (
    <button onClick={() => {history.push('/settings')}} className="profile-btn">
        <img src={Profile} alt="profile settings" className='nav-profile-icon'/>

    </button>
  )
}

export default ProfileBtn