import React from 'react'
import '../../css/buttons-inputs.css'

const Btn2 = ({text, onClick, type='button', form=null, muted=true}) => {
  return (
    <button 
    onClick={onClick} 
    type={type}
    className={muted ? 'btn-2' : 'btn-2 unmuted'}
    form={form}>
        
        {text}
        
    </button>
  )
}

export default Btn2