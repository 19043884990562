import React from 'react'
import {ReactComponent as Pin} from '../../assets/social-media-icons/pinterest.svg'
import '../../css/social-icons.css'
const Pinterest = () => {
  return (
    <Pin 
    viewBox="0 0 48 48"
    className="social-icon"
    />

  )
}

export default Pinterest