import React from 'react'
import Btn3 from '../buttons-inputs/Btn3'

import '../../css/general.css'
import '../../css/billing-plans.css'

const PlanItem = ({plan, select, selected}) => {
console.log(plan.background)
   return (
        <div className={selected.plan_id === plan.plan_id ? `plan-container ${plan.background} plan-selected` : `plan-container ${plan.background}`}>
                <div className='w-100 justify-content-center'>
                    <h1 className='text-primary'>{plan.title.charAt(0).toUpperCase()}{plan.title.slice(1)}</h1>
                </div>
                <div className='w-100 justify-content-center'>
                    <p className='text-primary m-0 p-0'>${plan.price / 100}</p>
                </div>
                <div className='w-100 justify-content-center'>
                    <p className='text-primary'>Monthly</p>
                </div>

                    {plan.details.map((detail) => {
                        return <div className='w-100 justify-content-center'>
                            <h4 className='text-primary'>{detail}</h4>
                        </div>
                    })
                    }
                <div className='w-100 justify-content-center'>
                    <Btn3 text={plan.plan_id === selected.plan_id ? "Current" : "Try Me"} onClick={() => select(plan)}/>
                </div>
            </div>
  )
}

export default PlanItem