import React from 'react'
import {ReactComponent as Snap} from '../../assets/social-media-icons/snapchat.svg'
import '../../css/social-icons.css'

const Snapchat = () => {
  return (
    <Snap
    viewBox="0 0 48 48"
    className="social-icon"
    />
  )
}

export default Snapchat