
import React from 'react'
//import Carousel from 'react-multi-carousel'
import { Carousel } from 'react-responsive-carousel';
import 'react-multi-carousel/lib/styles.css'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import '../../css/general.css'
import '../../css/containers.css'

const PostCarousel = ({files}) => {
    
    const responsive = {
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
      }
    }

  return (
    <div className='carousel-outer-container'>
      {/* <Carousel
      responsive={responsive}
      swipeable={true}
      autoplay={true}
      autoPlaySpeed={2000}
      keyBoardControl={true}
      centerMode={true}
      containerClass="carousel-outer-container"
      itemClass="carousel-img temp2"
      > */}

      <Carousel
      emulateTouch={true}
      showIndicators={false}
      showThumbs={false}
      responsive={responsive}
      >

      {files.map((file, index) => {
        return file.ext === 'mp4' ? 
        <video className='carousel-img' key={index} controls><source type='mp4' src={file.path}></source></video>
        :
        <img className='carousel-img' key={index} src={file.path} alt='scheduled post' responsive/>
        })
      }
      </Carousel>
    </div>
  )
  
}

export default PostCarousel