import React from 'react'
import '../../css/buttons-inputs.css'

const Input1 = ({id, placeholder, type='text', error=null, maxLength=5000}) => {
  return (
      <input 
      className={error === id ? 'input-main error' : 'input-main'} id={id} 
      name={id} 
      maxLength={maxLength} 
      placeholder={placeholder} 
      type={type}
      />
    )
}

export default React.memo(Input1)