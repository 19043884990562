import React, {useState} from 'react'
import EditBtn from '../buttons-inputs/EditBtn'
import EditSocialPopup from './EditSocialPopup'

import '../../css/platforms.css'
import '../../css/general.css'

//social icon imports
import Tiktok from '../social-icons/Tiktok'
import Twitter from '../social-icons/Twitter'
import Instagram from '../social-icons/Instagram'
import Facebook from '../social-icons/Facebook'

import getSocialIcon from '../../utils/GetSocialIcon'

const MyPlatformsItem = ({social}) => {
    const [toggleEditPopup, setToggleEditPopup] = useState(() => false)
    const handleToggleEditPopup = () => {setToggleEditPopup(!toggleEditPopup)}
    const usernameFormat = () => {
      return social.username.length > 12 ? social.username.slice(0, 12) : social.username
    }

  return (
    <div className='my-socials-item'>
        {getSocialIcon(social.platform.name)}
        <h3 className='text-primary'>{social.username ? usernameFormat() : social.platform.name}</h3>
        <EditBtn onClick={handleToggleEditPopup}/> 
        {
            toggleEditPopup &&
            <EditSocialPopup social={social} handleClosePopup={handleToggleEditPopup}/>
        }
    </div>
  )
}

export default MyPlatformsItem