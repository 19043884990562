import React from 'react'
import '../../css/buttons-inputs.css'

const Input2 = ({id, placeholder, onChange, type='text', error=null, maxLength=5000, value}) => {
  return (
    <input 
    className={error === id ? 'input2 error' : 'input2'} id={id} 
    name={id} 
    maxLength={maxLength} 
    placeholder={placeholder} 
    type={type}
    value = {value}
    onChange={onChange}
    />
  )
}

export default Input2