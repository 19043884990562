import React from 'react'
import PlatformsContainer from '../../components/platforms/PlatformsContainer'
import PreviewCalendarContainer from '../../components/calendar/PreviewCalendarContainer'

const Home = () => {
  return (
    <div className='w-100 h-100 justify-content-center'>
      <div className='justify-content-space-around w-100 p-20 m-10 flex-wrap-reverse'>
          <PlatformsContainer />
          <PreviewCalendarContainer/>
      </div>
    </div>
  )
}

export default Home