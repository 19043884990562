import React from 'react'
import {ReactComponent as FacebookIcon} from '../../assets/social-media-icons/facebook.svg'
import '../../css/social-icons.css'

const Facebook = () => {
  return (
    <FacebookIcon
    className="social-icon"
    viewBox="0 0 48 48"
    />
  )
}

export default Facebook