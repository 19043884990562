import React from 'react'
import {convertToFormattedSiteDateShort} from '../../utils/DateFunctions'
import '../../css/general.css'
import { useHistory } from 'react-router-dom'

const CalendarItem = ({post}) => {
    const date = convertToFormattedSiteDateShort(post.scheduled)
    const history = useHistory
  return (
    <div className='div-link w-100 justify-content-between' onClick={() => {history.push(`/post/${post.id}`)}}>
        <div className='m-20'>
            <p className='text-primary'>{post.title.length > 15 ? post.title.split(14) : post.title}</p>
        </div>
        <div className='m-20'>
            <p className='text-primary'>{date}</p>  
        </div>
    </div>
  )
}

export default CalendarItem