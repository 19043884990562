import React from 'react'
import {ReactComponent as Add} from '../../assets/tool-icons/plus-circle-main.svg'
import '../../css/buttons-inputs.css'
import '../../css/dividers-svgs.css'
const AddBtn = ({onClick=null}) => {
  return (
    <button onClick={() => onClick()}  className='btn-tools'> 
      <Add className='svg-xs' viewBox="0 0 24 24"/>
    </button>
  )
}

export default AddBtn