import React, {useContext, useState, useRef, useEffect} from 'react'
import { useParams } from 'react-router-dom'
import AuthContext from '../../context/AuthContext'
import CheckoutWrapper from '../../components/stripe/CheckoutWrapper'
import Divider1 from '../../components/dividers-logos/Divider1'
import ChangePlan from '../../components/billing/ChangePlan'

import '../../css/general.css'
import '../../css/checkout.css'


const Checkout = () => {
  const {Account} = useContext(AuthContext)
  // create var in context for plans
  const plans = [
    {pk:2,
      title:'Agent',
      price:'5000',
      background:'plan-bg3',
      details:['1 Platform', '1 Account Per Platform', 
              '10 Posts Per Month', '1GB of storage for scheduled posts',
              '50MB MAX Size of Post',]
      }
  ]
  const {planId} = useParams()

  
  const [toggleCheckout, setToggleCheckout] = useState(Account.has_default_payment_method ? false : true)
  
  const [plan, setPlan] = useState(() => false)


    useEffect(() => {
      for(let i = 0; i < plans.length; i++){
        if(plans[i].pk === parseInt(planId)){
          setPlan(plans[i])
          break
        }
      }
    }, [planId])


  return (
    <div className='checkout-container'>
      {plan &&
        <div className='mt-20 w-100 justify-content-center flex-wrap'>
          <h1 className='m-0 p-0 w-100 text-primary'>{plan.title}</h1>
          <div className='plan-details-wrapper'>
          {
            plan.details.map((detail, index) => {
              return <div className='plan-details-item' key={index}> 
                      <p className='text-primary' >{detail}</p>
                    </div>
            })
          }
          </div>
        </div>
      }

      <Divider1/>

      {plan && toggleCheckout &&
            <div className='w-100'>
              <CheckoutWrapper
              plan={plan}
              />
            </div>
      }
      {plan && !toggleCheckout && 
        <ChangePlan
        plan={plan}
        />
      }
    </div>
  )
}

export default Checkout