import React, {useContext, useState, useHistory, useEffect, useRef} from 'react'
import AuthContext from '../../context/AuthContext'
import PostsContext from '../../context/PostsContext'
import { convertToFormattedSiteDate } from '../../utils/DateFunctions'
import moment from 'moment'
import Btn2 from '../buttons-inputs/Btn2'
import Btn3 from '../buttons-inputs/Btn3'
import CalendarItem from './CalendarItem'
import '../../css/platforms.css'
import '../../css/general.css'
import '../../css/calendar.css'
import '../../css/containers.css'


const PreviewCalendarContainer = () => {
    const history = useHistory
    const {posts, paginatePosts} = useContext(PostsContext)
    const initialLoad = useRef(true)

    const [toggleAddPopup, setToggleAddPopup] = useState(() => false)
    const handleTogglePopup = () => {setToggleAddPopup(!toggleAddPopup)}

    const [postList, setPostList] = useState(false)
    // state for 7 or thirty days worth of posts
    const [toggleSeven, setToggleSeven] = useState(() => true)
    const [toggleThirty, setToggleThirty] = useState(() => false)

    //redirect to larger calendar if user selects one
    //years worth of posts
    const yearofPostsRedirect = () => history.push('/calendar')
  
    //sort through and group posts within range of inputed days
    const checkDate = (post, number = 7) => {
        const date = moment(new Date())
        const pDate = moment(convertToFormattedSiteDate(post.scheduled))
        const dif = pDate.diff(date, 'days', true)
        if(dif <= number){
          return post
        }
      }

    const handleSetDays = (numberOfDays=7) => {
      if(!posts) return

      const dArray = posts.results.filter(post => checkDate(post, numberOfDays))
      setPostList([...dArray])
    }
        

    const handleToggleDays = (numberOfDays) => {
      console.log(numberOfDays)
      console.log(toggleSeven)
      console.log(toggleThirty)
      if(numberOfDays === 30){
        setToggleSeven(() => false)
        setToggleThirty(() => true)
      }
      else{
        setToggleSeven(() => true)
        setToggleThirty(() => false)
      }

      handleSetDays(numberOfDays)

    }

    //handle pagination for posts and sort according to inputed days
    const handlePagination = async () => {
      if(!posts.next) return
      let number;
      if(toggleSeven) {
        number = 7
      }
      else{
        number = 30
      }

      const lastIndex = posts.results.slice(-1)
      const pDate = convertToFormattedSiteDate(lastIndex.scheduled)

      if(moment().diff(pDate, number) > number) return

      await paginatePosts()
    
    }

      
    const observer = useRef()

    const handleTrackPosition = element => {
      if(observer.current) {observer.current.disconnect()}
      observer.current = new IntersectionObserver(entries => {
        if(entries[0].isIntersecting){
          handlePagination()
        }
      })
      if(element) {observer.current.observe(element)}
    }

    //for setting initial state
    useEffect(() => {
      /* if(!initialLoad.current) return  */
      if(!posts || posts.count === 0) return
      handleSetDays(7)
      initialLoad.current = false
    }, [posts])
    
  
    return (
    <div className='card-container'>
      <div className='preview-calendar-header'>
          <div className='w-100 m-0 p-0 justify-content-center'>
                  <h3 className='text-primary m-0 p-0'> Calendar</h3>
          </div>
          <div className=' w-100 justify-content-space-around'>
              <Btn2 text='7 Days' muted={toggleThirty} onClick={() => handleToggleDays(7)}/>
              <Btn2 text='30 Days' muted={toggleSeven} onClick={() => handleToggleDays(30)}/>
              <Btn2 text='Year'  onClick={() => yearofPostsRedirect()}/>
          </div>
      </div>
      <div className='calendar-post-container'>
      {postList.length === 0 &&
            <div className='m-20'>
              <h4 className='text-primary'>You have nothing scheduled</h4>
              <div className='w-100 justify-content-center'>
                <Btn3 text='Schedule Post' onClick={() => history.push('schedule-post')}/>
              </div>
            </div>
          }
        {postList && postList.length > 0 &&
          postList.map((post, index) => {
            if(index + 1 === postList.length){
              return <>
                        <CalendarItem post={post} key={`${index}-calendar-item-${post.pk}`}/>
                        <div ref={handleTrackPosition}></div>
                    </> 
            }
            return <CalendarItem post={post} key={`${index}-calendar-item-${post.pk}`}/>
          })
        }
      </div>
    </div>
  )
}

export default PreviewCalendarContainer