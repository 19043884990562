import React from 'react'
import CheckoutPopup from './CheckoutPopup'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import '../../css/checkout.css'

const CheckoutWrapper = ({onComplete, onClose, plan}) => {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_SECRET)
    return (
    <div className='purchase-popup-container'>
      {stripePromise &&
        <Elements stripe={stripePromise}>
            <CheckoutPopup
                onComplete={onComplete} 
                onClose={onClose}
                plan={plan}
            />
        </Elements>
        }
    </div>
  )
}

export default React.memo(CheckoutWrapper)