import React, {useState, useContext} from 'react'
import AuthContext from '../../context/AuthContext'
import AccountInfoInput from './AccountInfoInput'
import BtnMain from '../buttons-inputs/BtnMain'

import { userProfileUrls } from '../../utils/ApiEndPoints'
import CustomFetch from '../../utils/CustomFetch'
import FormPayload from '../../utils/FormPayload'

import '../../css/general.css'

const UserInfo = () => {
    const {UserProfile, updateUserProfileState, Account} = useContext(AuthContext)
    const {updateUserProfile} = userProfileUrls

    const [email, setEmail] = useState(UserProfile.email)
    const [phone, setphone] = useState(UserProfile.phone)
    const [firstName, setfirstName] = useState(UserProfile.first_name)
    const [lastName, setLastName] = useState(UserProfile.last_name)
    const [companyName, setCompanyName] = useState(Account.company_name)
   
    const [loading, setLoading] = useState(() => false)
    const [error, setError] = useState({error:null, message:null})
    //make sure user has updated a field before submitting
    const [madeChanges, setMadeChanges] = useState(() => false)


    const handleSetField = (e, field) => {
        if(!madeChanges){setMadeChanges(() => true)}
        field(e.target.value)
    }

    const handleUpdateUserInfo = async (e) => {
        e.preventDefault()
        setLoading(true)
        if(!madeChanges) return
        let formPayload = FormPayload(e)
        formPayload = JSON.stringify(formPayload)
       
        const payload = {
            method:'PUT',
            body:formPayload}
        const {response, data} = await CustomFetch(`${updateUserProfile.url}${UserProfile.pk}/`,
                                        payload)
        
        if(response.status === 203){
            updateUserProfileState(data)
            setLoading(() => false)
            return
        }
        setLoading(() => false)
        setError({error:'request', message:response.status_text})
    }

  return (
    <form className='form-update' id='update-user-info' 
    method='PUT' onSubmit={(e) => handleUpdateUserInfo(e)}
    encType='multipart/form-data'
    >
        <div className='w-100 justify-content-between flex-wrap'>
            <div className='w-50'>
                <AccountInfoInput
                field={"First Name"}
                fieldValue={firstName}
                onChange={(e) => handleSetField(e, setfirstName)}
                error={error}
                id={'first_name'}
                />
            </div>
            <div className='w-50'>

                <AccountInfoInput
                field={'Last Name'}
                fieldValue={lastName}
                onChange={(e) => handleSetField(e, setLastName)}
                error={error}
                id={'last_name'}
                />
            </div>
        </div>
        

        <AccountInfoInput
        field={'Email'}
        fieldValue={email}
        onChange={(e) => handleSetField(e, setEmail)}
        error={error}
        id={'email'}
        />

        <AccountInfoInput
        fieldValue={phone}
        field={'Phone'}
        onChange={(e) => handleSetField(e, setphone)}
        error={error}
        id={'phone'}
        />

        {UserProfile.is_account_head &&
        
            <AccountInfoInput
            fieldValue={companyName}
            field={'Company Name (Optional)'}
            onChange={(e) => handleSetField(e, setCompanyName)}
            error={error}
            id={'company_name'}
            />
        }

        <div className='w-100 justify-content-center'>
            <BtnMain 
            text="Update" 
            form="update-user-info"
            />
        </div>
    </form>
  )
}

export default UserInfo