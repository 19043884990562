import React from 'react'
import {ReactComponent as TiktokIcon} from '../../assets/social-media-icons/tiktok.svg'
import '../../css/social-icons.css'
const Tiktok = () => {
  return (
    <TiktokIcon
    className="social-icon" 
    viewBox="0 0 48 48"
    />
  )
}

export default Tiktok