import React, {useContext, useState} from 'react'
import AuthContext from '../../context/AuthContext'
import '../../css/platforms.css'
import '../../css/general.css'
import BtnRed from '../buttons-inputs/BtnRed'
import Btn3 from '../buttons-inputs/Btn3'
import ExitBtn from '../buttons-inputs/ExitBtn'
import {socialPlatformUrls} from '../../utils/ApiEndPoints'
import CustomFetch from '../../utils/CustomFetch'
import Loading1 from '../../components/loading-errors-success/Loading1'
import Error1 from '../../components/loading-errors-success/Error1'
import getSocialIcon from '../../utils/GetSocialIcon'

const EditSocialPopup = ({social, handleClosePopup}) => {
  const {getUserProfileInfo, UserProfile} = useContext(AuthContext)
  const {deleteSocial, refreshSocial} = socialPlatformUrls

  const [loading, setLoading] = useState(() => false)
  const [error, setError] = useState(() => false)

  const handleDeleteSocial = async () => {
    setLoading(true)
    const {response, data} = await CustomFetch(`${deleteSocial.url}${social.pk}`)
    setLoading(false)
    if(response.status === 203){
      getUserProfileInfo(UserProfile.id)
      return
    }
    setError('Requet could not be processed at this time')
  }


  const handleRefreshSocial = async () => {
    setLoading(true)
    const {response, data} = await CustomFetch(`${refreshSocial.url}${social.pk}`)
    setLoading(false)
    if(response.status === 203){
      getUserProfileInfo(UserProfile.id)
      return
    }
    setError('Request could not be processed at this time')
  }


  return (
    <div className='popup-main-container'>
      <div className='edit-social-popup-container'>
        <div className='w-100 justify-content-end'>
          <ExitBtn onClick={() => handleClosePopup()}/>
        </div>
        <div className='w-75 justify-content-center flex-wrap'>
          {getSocialIcon(social.platform.name)}
        </div>
        <div className='w-75 justify-content-center'>
          <h3 className='text-primary'>{social.username ? social.username : social.platform.name}</h3>
        </div>
        <div className='justify-content-space-around w-75 p-20'>
          <BtnRed text={'Delete'} onClick={() => handleDeleteSocial()}/>
          <Btn3 text={'Refresh'} onClick={() => handleRefreshSocial()}/>
        </div>
        {
          error &&
          <Error1 error={error}/>
        }
        {
          loading &&
          <Loading1/>
        }
      </div>
    </div>
  )
}

export default EditSocialPopup