import React, {useState, useContext} from 'react'
import AuthContext from '../../context/AuthContext'
import PostsContext from '../../context/PostsContext'
import UploadFormV2 from '../../components/forms/UploadFormV2'
import { useHistory } from 'react-router-dom'
import CustomFetch from '../../utils/CustomFetch'
import {postUrls} from '../../utils/ApiEndPoints'
import Loading1 from '../../components/loading-errors-success/Loading1'
import formInputChecker from '../../utils/FormInputChecker'
import { convertLocalToDbTime, checkIsFuture } from '../../utils/DateFunctions'
import {S3UploadFiles} from '../../utils/s3_utils'
import '../../css/general.css'

const SchedulePost = () => {
  const {UserProfile} = useContext(AuthContext)
  const {handleAddPostToState} = useContext(PostsContext)
  const [loading, setLoading] = useState(() => false)
  const [error, setError] = useState(() => false)
  const [inputError, setInputError] = useState(() => false)
  const {schedulePost} = postUrls

const [date, setDate] = useState(() => false)

  const history = useHistory

  const controller = new AbortController()
  const signal = controller.signal
  const abort = () => {
    controller.abort()
    setLoading(false)
}

  const handleFormSubmit = async (e, platforms) => {
    e.preventDefault()
    let files = e.target.file.files

    if(Object.keys(platforms).length === 0){
      setError({error:'platforms',errorMessage:'Select platforms'})
      return
    }

    setLoading(() => true)

    const has_error = formInputChecker(e, setInputError, ['files', 'body'])
    if(has_error){
      setLoading(() => false)
      setError({error:'input', errorMessage:'Input error'})
      return
    }

    if(!e.target.body.value && !e.target.file.files[0]){
      setError({error:'body', errorMessage:'Provide description or media content'})
      setLoading(() => false)
      return
    }

    //convert local date to server time and check if date is in range
    const dateDbTime = convertLocalToDbTime(date.year, date.month,
                                      date.day, date.hour, 
                                      date.minute)
    
    const checkDate = checkIsFuture(dateDbTime)
    
    if(!checkDate){
      setError({error:'calendar', errorMessage: 'Date must be in the future'})
      setLoading(() => false)
      return
    }

    //handle file uploads
    //takes in list of files
    //returns a dictionary with list of filepaths 
    //and any errors
    const returnedFileList = await S3UploadFiles(files)
    const fileList = returnedFileList.keyList

    if(returnedFileList.errors){
      setError({error:'Files', errorMessage:returnedFileList.errors})
      setLoading(() => false)
      return
    }

    const payload = new FormData()
    //add basic data to payload
    payload.append('title', e.target.nickname.value)
    payload.append('scheduled', dateDbTime)
    payload.append('body', e.target.body.value)
    payload.append('user', UserProfile.pk)
    
    //add social accounts to payload
    const platformValues = Object.values(platforms)
    const socialIdList = platformValues[0].map((val) => {
      return val.id
    })
   
   socialIdList.forEach((id) => payload.append('social_accounts', id))
    

    //add files to payload
    
    if(fileList.length > 0){
      fileList.forEach((file) => {
      payload.append("urllist", file)
      })
    }


    const fetchConfig = {method:'POST', signal:signal,  body:payload}

    const {response, data} = await CustomFetch(schedulePost.url, fetchConfig, true)

    if(response.status === schedulePost.success){
      handleAddPostToState(data)
      history.push('/posts')
    }
    
    setLoading(() => false)
    setError({error:'response', errorMessage:'Something went wrong, try again later'})
}


  return (
    <div className='h-100 w-100 justify-content-center'>
        {
          loading ?
          <div className='form-main'>
            <Loading1 btnAction={abort}/>
          </div>  
          :
          <UploadFormV2 action={handleFormSubmit} 
          loading={loading}
          inputError={inputError ? inputError : error}
          setDate = {setDate}
          />
        }
    </div>
  )
}

export default SchedulePost