import './App.css';
import './css/general.css';
import { Route, BrowserRouter as Router} from 'react-router-dom';
import PrivateRoute from './utils/PrivateRoute'
import { AuthProvider } from './context/AuthContext';
import { PostsProvider } from './context/PostsContext';
import Sidebar from './components/nav/Sidebar';
import Navbar from './components/nav/Navbar';
import Landing from './pages/Landing';

import LoginSignup from './pages/auth/LoginSignup';
import SchedulePost from './pages/posts/SchedulePost';
import Home from './pages/home/Home';
import Account from './pages/auth/Account';
import PasswordResetConfirm from './pages/auth/PasswordResetConfirm';
import Plans from './pages/billing/Plans';
import Checkout from './pages/billing/Checkout';
import PrivacyPolicy from './pages/basic/PrivacyPolicy';
import Contact from './pages/basic/Contact';


function App() {
  return (
    <Router>
      <AuthProvider>
      <PostsProvider>
        <Navbar/>
        <div className='main-body-container'>
          <div className='child-body-container'>
            <Route component={Landing} path='/' exact></Route>
            <Route component={LoginSignup} path='/login-signup'></Route>
            <Route component={PasswordResetConfirm} path='/password/reset_confirm/'></Route>
            <PrivateRoute component={SchedulePost} path='/schedule-post'></PrivateRoute>
            <PrivateRoute component={Home} path='/home'></PrivateRoute>
            <PrivateRoute component={Account} path='/settings'></PrivateRoute>
            <Route component={Plans} path='/plans'></Route>
            <PrivateRoute component={Checkout} path='/checkout/:planId'></PrivateRoute>
            <Route path='/privacy-terms' component={PrivacyPolicy}></Route>
            <Route path='/contact' component={Contact}></Route>
          </div>
          <div className='child-body-container nav'>
            <Sidebar/>
          </div>

        </div>
        <div className='w-100 justify-content-center'>
          <p className='text-primary'>Copyright Stray Technology 2023. Twisted plan / Twisted Plans is owned and brought to you 
          by parent company Stray Technology.</p>
        </div>
      </PostsProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
