import React from 'react'
import Pencil from '../../assets/tool-icons/pencil.png'
import '../../css/buttons-inputs.css'
import '../../css/dividers-svgs.css'
const EditBtn = ({onClick}) => {
  return (
    <button onClick={onClick}  className='btn-tools'> 
        <img src={Pencil} alt={'edit social platform'} className='svg-xs'/>
    </button>
  )
}

export default EditBtn