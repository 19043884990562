import React from 'react'
import Input2 from '../buttons-inputs/Input2'
import '../../css/general.css'

const AccountInfoInput = ({id, error, field, fieldValue, onChange}) => {
  return (
    <div className='w-100 m-10 justify-content-start flex-wrap'>
        <div className='w-100 p-10'>
            <h4 className='text-primary p-0 m-0'>{field}</h4>
        </div>
        <Input2 
        value={fieldValue}
        onChange={onChange}
        id = {id}
        error = {error}
        />
    </div>
  )
}

export default AccountInfoInput