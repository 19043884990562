import React, {useContext, useState} from 'react'
import AuthContext from '../../context/AuthContext'
import BtnMain from '../buttons-inputs/BtnMain'
import Loading1 from '../loading-errors-success/Loading1'
import Error1 from '../loading-errors-success/Error1'
import Divider1 from '../dividers-logos/Divider1'
import '../../css/general.css'
import '../../css/forms.css'

const Security = () => {
const {resetPassword, UserProfile} = useContext(AuthContext)

const [error, setError] = useState(null)
const [loading, setLoading] = useState(false)
const [status, setStatus] = useState(null)

  const handlePasswordResetRequest = async () => {
    setLoading(true)
    const response = await resetPassword(UserProfile.email)
    setLoading(false)
    if(response.status === 204){
      setStatus("Check your email!")
      return
    }
    setError({message:"Try again later"})
  }
  return (
    <div className='form-update mt-20'>
        <Divider1/>
        <div className='w-100 justify-content-center'>
            {loading &&
                <Loading1/>
            }
            {error &&
                <Error1 error={error}/>
            }
            {status &&
                <h1 className='text-primary'>{status}</h1>
            }
        </div>
        <div className='w-100 mt-20 justify-content-center'>
            {!status &&
                <BtnMain 
                text="Reset Password" 
                onClick={handlePasswordResetRequest}
                />
            }
        </div>
    </div>
  )
}

export default Security