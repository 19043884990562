import React, {useState} from 'react'
import Input1 from '../../components/buttons-inputs/Input1'
import BtnMain from '../../components/buttons-inputs/BtnMain'
import TextareaAutosize from 'react-textarea-autosize';
import '../../css/general.css'
import '../../css/forms.css'

const Contact = () => {
    const fields = {}
    const [error, setError] = useState(() => false)
    const [body, setBody] = useState('')
    const handleSetBody = (e) => [
        setBody(e.target.value)
    ]
  return (
    <div className='w-100 mt-50'>
      <form className='w-75 form-main' id='contact-form'>
                  <div className='w-75 justify-content-center flex-wrap'>
                      <Input1 error={error} placeholder='Username (If applicable)' type='text' id='username'/>
                      <Input1 error={error} placeholder='Email' type='email' id='email'/>
                      <TextareaAutosize
                      id='body'
                      name='body'
                      minRows={5}
                      className={error.error === 'body'? 'resize-input error' : 'resize-input'}
                      placeholder='Describe your reason for contacting us'
                      onChange = {(e) => {handleSetBody(e)}}
                      />
                      <div className='w-100 justify-content-center'>
                          <BtnMain form='contact-form' text='Send'/>
                      </div>
                  </div>
          </form>
    </div>
    
  )
}

export default Contact