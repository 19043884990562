import React, {useState} from 'react'
import '../../css/forms.css'
import { useHistory } from 'react-router-dom'
import Error1 from '../loading-errors-success/Error1'
import Loading1 from '../loading-errors-success/Loading1'
import '../../css/general.css'
import Input1 from '../buttons-inputs/Input1'
import BtnMain from '../buttons-inputs/BtnMain'
import FormInputChecker from '../../utils/FormInputChecker'
import BasicFetch from '../../utils/BasicFetch'
import { signupUrl } from '../../utils/ApiEndPoints'

const SignupForm = () => {
    const [loading, setLoading] = useState(() => false)
    const [error, setError] = useState(() => false)

    const history = useHistory

    const handleSignup = async (e) => {
        e.preventDefault()
        const inputError = FormInputChecker(e, setError)
        if(inputError) return

        if(e.target.password.value !== e.target.verifypassword.value){
            setError('Passwords do not match')
        }
        
        setLoading(() => true)

        const fetchConfig = {
            method: 'POST',
            body:JSON.stringify({
                email:e.target.email.value,
                password:e.target.password.value,
                username:e.target.username.value
            })
        }

        const {response, data} = await BasicFetch(signupUrl, fetchConfig)

        setLoading(() => false)

        if(response.status === 204){
            history.push('/home')
            return
        }

        setError(data)
        setLoading(() => false)
    }

  return (
    <form className='form-main border-none' id='signup-form' onSubmit={handleSignup}>
        {!loading &&
            <div className='w-100 justify-content-center flex-wrap'>
                <Input1 error={error} placeholder='Username' type='text' id='username'/>
                <Input1 error={error} placeholder='Password' type='password' id='password'/>
                <Input1 error={error} placeholder='Verify Password' type='password' id='verifypassword'/>
                <Input1 error={error} placeholder='Email' type='email' id='email'/>
                <BtnMain form='signup-form' text='Signup'/>
            </div>
        }


        {loading &&
            <Loading1/>
        }

        {
            error &&
            <div className='w-75 mt-10'>
                <Error1 error = {error}/>
            </div>
        }
    </form>
  )
}

export default SignupForm