import React from 'react'
import '../../css/buttons-inputs.css'

const BtnMain = ({onClick = null, form = null, text = null
                  ,disabled=false}) => {
  return (
    <button className='btn-main' onClick={onClick} form={form}
    disabled={disabled}>{text}</button>
  )
}

export default BtnMain