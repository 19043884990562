import React, {useState, useContext} from 'react'
import { useHistory } from 'react-router-dom'
import AuthContext from '../../context/AuthContext'
import PlanItem from '../../components/billing/PlanItem'
import ChangePlan from '../../components/billing/ChangePlan'
import planInformation from '../../utils/planInformation'
import '../../css/general.css'


const Plans = () => {

    const history = useHistory()
    const {Account, UserProfile} = useContext(AuthContext)
    const [selected, setSelected] = useState(() => {
            return UserProfile ? planInformation.filter((plat) => {return plat.plan_id === Account.subscription.product.plan_id})[0]
            : planInformation.filter((plat) => {return plat.plan_id === 'default'})[0]
            }
        )

    const [toggleUpdatePlan, setToggleUpdatePlan] = useState(() => false)
    
    const handleToggleUpdatePopup = () => {
        setToggleUpdatePlan(!toggleUpdatePlan)
    }

    const handleSelect = (plan) => {
        setSelected(plan)
        if(plan.plan_id === Account.subscription.product.plan_id){
            return
        }
        if(UserProfile && Account.subscription.product.plan_id === 'default'){
            history.push(`/checkout/${plan.plan_id}`)
            return
        }
        if(UserProfile && Account.subscription.product.plan_id !== 'default'){
            handleToggleUpdatePopup()
        }
        history.push('/login-signup')
    }



  return (
    <div className='w-100 justify-content-space-around flex-wrap mt-20 '>
        {
            planInformation.map((plan, index) => {
                return <PlanItem 
                key={`${index}-${plan.plan_id}`} 
                plan={plan} 
                select={handleSelect} 
                selected={selected}/>
            })
        }
        {toggleUpdatePlan &&
            <ChangePlan plan={selected} onClose={() => handleToggleUpdatePopup}/>
        }
    </div>
  )
}

export default Plans