import React, {useContext, useState} from 'react'
import ProfileBtn from './ProfileBtn'
import Btn3 from '../buttons-inputs/Btn3'
import BtnMain from '../buttons-inputs/BtnMain'
import AuthContext from '../../context/AuthContext'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import ExitBtn from '../buttons-inputs/ExitBtn'
import Navbar from './Navbar'
import '../../css/navbar.css'

const Sidebar = () => {
    const history = useHistory()

    const {logoutUser, User} = useContext(AuthContext)

    const [toggleMenu, setToggleMenu] = useState(() => false)
    const handleToggleMenu = () => {
        setToggleMenu(!toggleMenu)
    }

    return (
    <div className='side-nav-main'>
        <Navbar toggle = {handleToggleMenu} mobile={true}/>
        <div className={toggleMenu ? 'side-nav-container active' : 'side-nav-container'}>
            <div className='justify-content-end mobile-only'>
                <ExitBtn onClick={handleToggleMenu}/>
            </div>
            {User && <>
            <div className='side-nav-item mt-10' onClick={() => history.push('/schedule-post')}>
                <Btn3 text='New Post'/>
            </div>

            <div className='side-nav-header' onClick={() => {history.push('/settings')}}>
                <div className='w-100 justify-content-center'>
                    <ProfileBtn/>
                </div>
                <div className='nav-item justify-content-center'>
                    <h4 className='text-primary'>Account</h4>
                </div>
            </div>

            <div className='side-nav-item' onClick = {() => history.push('/home')}>
                <h4 className='text-primary'>Dashboard</h4>
            </div>


            <div className='side-nav-item' onClick={() => logoutUser()}>
                <h4 className='text-primary'>Sign out</h4>
            </div>

            <div className='side-nav-item' onClick={() => logoutUser()}>
                <h4 className='text-primary'>Notifications</h4>
            </div>
            </>
            }
            {!User &&
                <>
                    <div className='side-nav-item m-10' >
                        <BtnMain
                        text='Login / Signup'
                        onClick={() => history.push('/login-signup')}
                        />
                    </div>
                    <div className='side-nav-item' onClick={() => history.push('/plans')}>
                        <h4 className='text-primary'>Pricing</h4>
                    </div>
                </>
            }
            <div className='side-nav-item' onClick={() => history.push('/contact')}>
                <h4 className='text-primary'>Contact Us</h4>
            </div>
            {!User &&
                <div className='side-nav-item' onClick={() => history.push('/privacy-terms')}>
                    <h4 className='text-primary'>Privacy / Terms</h4>
                </div>
            }
        </div>
    </div>
  )
}

export default Sidebar