import React from 'react'
import {ReactComponent as LinkedInIcon} from '../../assets/social-media-icons/linkedin.svg'
import '../../css/social-icons.css'
const LinkedIn = () => {
  return (
    <LinkedInIcon     
    viewBox="0 0 48 48"
    className="social-icon"
    />
  )
}

export default LinkedIn