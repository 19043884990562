import React, {useState} from 'react'
import CustomFetch from '../../utils/CustomFetch'
import { checkoutUrls} from '../../utils/ApiEndPoints'
import { CardElement, useStripe, useElements} from '@stripe/react-stripe-js'
import Loading1 from '../loading-errors-success/Loading1'
import Success1 from '../loading-errors-success/Success1'
import Error1 from '../loading-errors-success/Error1'
import BtnMain from '../buttons-inputs/BtnMain'

import '../../css/checkout.css'
import '../../css/general.css'



const UpdateCardForm = ({onComplete}) => {
  
   //for styling stripe card element
   const cardOptions= {
    style: {
      base: {
        fontSize: '20px',
        color: 'rgb(236, 237, 238)',
        '::placeholder': {
          color: 'rgb(236, 237, 238)',
        },
      },
      invalid: {
        color: '#9e2146',
      },
    },
  }

const stripe = useStripe()
const elements = useElements()


const {UpdatePaymentMethod} = checkoutUrls

const [btnDisabled, setBtnDisabled] = useState(() => false)
const [processing, setProcessing] = useState(() => false)
const [showError, setShowError] = useState(() => false)
const [success, setSuccess] = useState(() => false)



const handlePayment = async () =>{
    setBtnDisabled(true)
    setProcessing(true)
    const status = await updateCard()
    setProcessing(false)
    if(!status){
        setBtnDisabled(false)
        setShowError({error:"card info", message: "An Error has occurred, try again later"})
        return
    }
    setSuccess(true)
    onComplete()
}

const updateCard = async () => {
    const fetchConfig = {method: UpdatePaymentMethod.method}
    const {response, data} = await CustomFetch(UpdatePaymentMethod.url, fetchConfig)
    console.log(data)
    console.log(data.client_secret)
    if(response.status === UpdatePaymentMethod.success){
        const {error, paymentIntent} = await stripe.confirmCardPayment(data.client_secret, {
            payment_method:{
                type: 'card',
                card: elements.getElement(CardElement),
                }
            })

        if(error){
            return false
        }
        return true
    }
}

  
  
    return (
        <div className='popup-content-container'>
        <div className='justify-content-center w-100'>
            <h2 className='text-primary m-0 p-0'>Update Card</h2>
        </div>
            <div className='card-input-wrapper'>
                <CardElement options={cardOptions}/>
            </div>
            <div className='justify-content-center m-20 p-10 w-100'>
                { processing ? <Loading1/> :
                    <BtnMain text="Confirm Update Card" onClick={()=>handlePayment()} disabled={!stripe || !elements || btnDisabled}/>
                }
                {success &&
                    <Success1/>
                }
                {showError &&
                    <Error1 error={showError}/>
                }
            </div>

    </div>
  )
}

export default React.memo(UpdateCardForm)