import React, {useEffect, useState} from 'react'
import { CountRenders } from '../../utils/CountRenders'
import '../../css/general.css'
import '../../css/dividers-svgs.css'
import '../../css/buttons-inputs.css'

const SocialAccountSelectItem = ({account, onClick, selectedAccountList}) => {
  const inList = () => {
    if(!selectedAccountList) {return false}

    for(let i = 0; i < selectedAccountList[account.platform.name].length; i++){
      if(selectedAccountList[account.platform.name][i].id === account.id){
        return true
      }
    }
    return false
  }
  
  let style = inList()
  console.log(account.username)
  console.log(style)
  return (
    <button type="button" id={account.username} className={inList() ? "social-account-select-item hover-bg-2" : 'social-account-select-item hover-bg-1'}
    onClick={() => onClick(account)}>
        {account.username}
    </button>
  )
}

export default SocialAccountSelectItem