import React, {useState, useContext} from 'react'
import platformInformation from '../../utils/PlatformInformation'
import '../../css/general.css'
import '../../css/dividers-svgs.css'
import AuthContext from '../../context/AuthContext'
import AddPlatformsPopup from '../platforms/AddPlatformsPopup'
import SocialAccountSelectPopup from './SocialAccountSelectPopup'
import getSocialIconSmall from '../../utils/GetSocialIconSmall'
import { CountRenders } from '../../utils/CountRenders'
import SocialSelect from '../buttons-inputs/SocialSelect'

//takes in a ref for users selected accounts to be added to
const PlatformSelectorV2= ({selectedAccounts, setSelectedAccounts}) => {
    CountRenders('platform v2: ')

    //sorted list of all account belonging to a user
    const {UserProfile, userSocials} = useContext(AuthContext)
    
    //for adding a social account to a platform user has not added an
    //account for yet
    const [addPlatformPopup, setAddPlatformPopup] = useState(() => false)
    
    //popup for selecting social media account for corresponding platform
    //is set with platform object
    const [selectAccountPopup, setSelectAccountPopup] = useState(()=> false)

    //removes account if selected account is already in account list
    //add's if not
    //adds or removes platform from selected platfrorms list for style rendering
    const handleSelectAccounts = (account) => {
        //if first time user has selected account from this platform
        //add platform key and account value to list
        if(!selectedAccounts[account.platform.name]){
            let tempList = {...selectedAccounts}
            tempList[account.platform.name] = [account]
            setSelectedAccounts({...tempList})
            return
        }

        // if account is already in list, remove
        //if there are no accounts for this platform added to list,
        // remove platform, else keep platform in list
        if(selectedAccounts[account.platform.name].length >= 1){
            //loop through accounts in that platform {'platform', [{account1, account2}]}
            for(let i = 0; i < selectedAccounts[account.platform.name].length; i++){
                //if that account is already in the list
                //filter through all of the accounts and return them in a new list
                //leaving out the account being removed
                if(selectedAccounts[account.platform.name][i].id === account.id){
                    if(selectedAccounts[account.platform.name].length === 1){
                        //if platform only has one account that is being removed
                        //delete entire key value pair
                        let tempList = {...selectedAccounts}
                        delete tempList[account.platform.name]
                        setSelectedAccounts({...tempList})
                        return
                    }
                    
                    
                    const filteredAccountList = selectedAccounts[account.platform.name].filter(
                    (acc, index) => {
                        return acc.id !== account.id
                    })

                    setSelectedAccounts(prevObj => {
                        return ({...prevObj, [account.platform.name]:[...filteredAccountList]})
                    })
                    return
                }

            }
            //if account is not already in list, add
            let tempList = {...selectedAccounts}
            tempList[account.platform.name].push(account)
            setSelectedAccounts({...tempList})
            return
        }

    }


    //decides whether to display popup for adding a social account
    //or if user already has one or more accounts for platform,
    //display account selection popup
    const handlePopups = (platform) => {
        //if  user already has an account
        console.log(userSocials)
        if(userSocials[platform.name]){
            setSelectAccountPopup(() => platform)
            return
        }
        
        //if user has not already added account for platform
        setAddPlatformPopup(() => true)
    }

    //changes styling for platform
    const handleRenderSelected = (platform) => {
        if(selectedAccounts[platform]){
            return true
        }
        return false
    }

  return (
    <div className='p-20 w-100 justify-content-space-around flex-wrap p-20'>
        {addPlatformPopup &&
            <AddPlatformsPopup handleClosePopup={() => setAddPlatformPopup(false)}/>
        }

        {selectAccountPopup &&
            <SocialAccountSelectPopup 
            socialAccounts = {userSocials[selectAccountPopup.name]}
            handleSelectAccounts = {handleSelectAccounts}
            handleClosePopup={() => setSelectAccountPopup(false)}
            selectedAccountList = {selectedAccounts}
            />
        }
        
        {platformInformation && 
            platformInformation.map((plat, index) => {
                let platIcon = getSocialIconSmall(plat.name)
                return <SocialSelect
                key={index}
                icon={platIcon ? platIcon : null}
                url = {!platIcon ? plat.icon : null}
                id = {plat.name}
                onClick = {() => handlePopups(plat)}
                selected = {handleRenderSelected(plat.name)}
                />
            })
        }
       
               

    </div>
  )
}


export default PlatformSelectorV2