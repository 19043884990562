import React from 'react'
import platformInformation from '../../utils/PlatformInformation'
import { socialPlatformUrls } from '../../utils/ApiEndPoints'
import SupportedPlatformItem from './SupportedPlatformItem'
import ExitBtn from '../buttons-inputs/ExitBtn'

import '../../css/platforms.css'
import '../../css/general.css'

const AddPlatformsPopup = ({handleClosePopup}) => {

    const {addSocial} = socialPlatformUrls

    const handleSelectPlatform = (platform) => {
        const url = `${addSocial.url}?platform=${platform}`
        window.location.replace(url)
    }

  return (
    <div className='popup-main-container'>
        <div className='platform-popup-container'>
        <div className='w-100 justify-content-end p-10'>
          <ExitBtn onClick={() => handleClosePopup()}/>
        </div>
            <div className='w-100 justify-content-center'>
                <h3 className='text-primary'> Choose Platform</h3>
            </div>
            <div className='w-100 justify-content-center flex-wrap'>
                {
                    platformInformation.map((plat, index) => {
                        return <SupportedPlatformItem 
                        platform = {plat} 
                        key={`${index}-platform-${plat.pk}`} 
                        onClick={handleSelectPlatform}
                        />
                    })
                }
            </div>
        </div>
    </div>
  )
}

export default AddPlatformsPopup