import React, {useState, useContext} from 'react'
import { useHistory } from 'react-router-dom'
import AuthContext from '../../context/AuthContext'
import Loading1 from '../../components/loading-errors-success/Loading1'
import Error1 from '../../components/loading-errors-success/Error1'
import Input1 from '../../components/buttons-inputs/Input1'
import BtnMain from '../../components/buttons-inputs/BtnMain'

import formInputChecker from '../../utils/FormInputChecker'

import '../../css/general.css'
import '../../css/forms.css'

const PasswordResetConfirm = () => {
    const history = useHistory()
    const {handleConfirmPasswordReset} = useContext(AuthContext)
    const [loading, setLoading] = useState(() => false)
    const [error, setError] = useState(() => false)
    const [success, setSuccess] = useState(() => false)

    const handlePasswordReset = async (e) => {
        const token = ""
        const uid = ""
        e.preventDefault()
        setLoading(true)

        const formErrors = formInputChecker(e, setError)
        if(formErrors){
            setLoading(false)
            setError({error:formErrors})
            return
        }

        const response = await handleConfirmPasswordReset(uid, token,
                                        e.target.new_password.value, 
                                        e.target.re_new_password.value)
        if(response.status === 204){
            setLoading(false)
            setSuccess("Your Password Has Been Reset!")
            history.push('/login-signup')
            return
        }
        setError({error:"passwordreset", message:"An error has occurred. Try again later "})

    }


  return (
    <form className='form-main' id="password-reset" onSubmit={handlePasswordReset}>
        {success &&
            <h1 className='text-primary'>{success}</h1>
        }
        {error &&
            <Error1 error={error}/>
        }
        
        {loading && !error && !success?
            <Loading1/>
        :
            <>
                <div className='w-100 justify-content-center'>
                <Input1 
                placeholder={'Password'}
                id={'new_password'}
                error={error}
                />
            </div>
            <div className='w-100 justify-content-center'>
                <Input1 
                placeholder={'ReType Password'}
                id={'re_new_password'}
                error={error}
                />
            </div>
            <div className='w-100 justify-content-center'>
                <BtnMain text="Reset Password" form="password-reset"/>
            </div>
            </>

        }
        
    </form>
  )
}

export default PasswordResetConfirm